/**
 * @file Front Page.
 */

/**
 * Front Page functions.
 *
 * Runs js specific to the Front Page template.
 */
const vFrontPage = () => {
	import('../../components/hp-hero/hp-hero').then((m) => m.default());
	import('../../components/hp-intro/hp-intro').then((m) => m.default());
	import('../../components/hp-programs/hp-programs').then((m) => m.default());
	if (document.querySelector('.v-carousel')) import('../../components/carousel/carousel').then((m) => { m.default(); });
};

if (document.readyState !== 'loading') {
	vFrontPage();
} else {
	document.addEventListener('DOMContentLoaded', vFrontPage);
}
